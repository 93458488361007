import { render, staticRenderFns } from "./updateTOfficeTerm.vue?vue&type=template&id=ef684f78&"
import script from "./updateTOfficeTerm.vue?vue&type=script&lang=js&"
export * from "./updateTOfficeTerm.vue?vue&type=script&lang=js&"
import style0 from "./updateTOfficeTerm.vue?vue&type=style&index=0&id=ef684f78&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ef684f78')) {
      api.createRecord('ef684f78', component.options)
    } else {
      api.reload('ef684f78', component.options)
    }
    module.hot.accept("./updateTOfficeTerm.vue?vue&type=template&id=ef684f78&", function () {
      api.rerender('ef684f78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tOfficeTerm/updateTOfficeTerm.vue"
export default component.exports