var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tOfficeTerm",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tOfficeTermForm",
          attrs: {
            model: _vm.tOfficeTermForm,
            rules: _vm.tOfficeTermFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "科室名称", prop: "officeId" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tOfficeTermForm.officeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.tOfficeTermForm, "officeId", $$v)
                    },
                    expression: "tOfficeTermForm.officeId",
                  },
                },
                _vm._l(_vm.officeIdPriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.sectionName))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "体检项目", prop: "portfolioProjectId" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tOfficeTermForm.portfolioProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.tOfficeTermForm, "portfolioProjectId", $$v)
                    },
                    expression: "tOfficeTermForm.portfolioProjectId",
                  },
                },
                _vm._l(_vm.tPortfolioProjectList, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "体检类型", prop: "inspectType" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    multiple: "",
                    transfer: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tOfficeTermForm.inspectType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tOfficeTermForm, "inspectType", $$v)
                    },
                    expression: "tOfficeTermForm.inspectType",
                  },
                },
                _vm._l(_vm.inspectTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "术语类型", prop: "type" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tOfficeTermForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tOfficeTermForm, "type", $$v)
                    },
                    expression: "tOfficeTermForm.type",
                  },
                },
                _vm._l(_vm.officeTermTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "危害因素", prop: "hazardFactors" } },
            [
              _c("TreeSelect", {
                ref: "hazardFactors",
                attrs: { data: _vm.hazardFactorsPriority, clearable: "" },
                on: { "on-change": _vm.hazardFactorsChange },
                model: {
                  value: _vm.tOfficeTermForm.hazardFactors,
                  callback: function ($$v) {
                    _vm.$set(_vm.tOfficeTermForm, "hazardFactors", $$v)
                  },
                  expression: "tOfficeTermForm.hazardFactors",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "在岗状态", prop: "workStateCode" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    multiple: "",
                    transfer: "",
                    "label-in-value": "",
                  },
                  on: { "on-change": _vm.workChange },
                  model: {
                    value: _vm.tOfficeTermForm.workStateCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.tOfficeTermForm, "workStateCode", $$v)
                    },
                    expression: "tOfficeTermForm.workStateCode",
                  },
                },
                _vm._l(_vm.workStateCodeArr, function (item, index) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "RadioGroup",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.tOfficeTermForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tOfficeTermForm, "status", $$v)
                    },
                    expression: "tOfficeTermForm.status",
                  },
                },
                _vm._l(_vm.statusRadio, function (item, i) {
                  return _c(
                    "Radio",
                    {
                      key: i,
                      attrs: { disabled: _vm.disabled, label: item.value },
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "术语内容", prop: "content" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  rows: 3,
                  type: "textarea",
                  placeholder: "请输入术语内容",
                },
                model: {
                  value: _vm.tOfficeTermForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.tOfficeTermForm, "content", $$v)
                  },
                  expression: "tOfficeTermForm.content",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "排序", prop: "orderNum" } },
            [
              _c(
                "Tooltip",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    trigger: "hover",
                    placement: "right",
                    content: "值越小越靠前，支持小数",
                  },
                },
                [
                  _c("InputNumber", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      max: 1000,
                      min: 0,
                      disabled: _vm.disabled,
                      placeholder: "请输入排序",
                    },
                    model: {
                      value: _vm.tOfficeTermForm.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.tOfficeTermForm, "orderNum", $$v)
                      },
                      expression: "tOfficeTermForm.orderNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }